import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { Carrot  } from "lucide-react";
function Navbar2() {
  return (
    <Navbar
      expand="lg"
      className="navbar-dark"
      style={{ backgroundColor: "#A87676",padingTop:5,padingHorizontal:0 }}
    >
      <Container style={{marginLeft:0}}>
        <NavLink className="navbar-brand" to="/">
          <div style={{ display: "flex" }}>
            <Carrot  size={35} />
            <h4 className="pt-2 pt-lg-1">algoriturk</h4>
          </div>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className="nav-link" to="/">
              Home
            </NavLink>
            <NavLink className="nav-link" to="/games">
              Games
            </NavLink>
            <NavLink className="nav-link" to="/blog">
              Blog
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar2;
