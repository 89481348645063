/* eslint-disable jsx-a11y/img-redundant-alt */
import { Carousel } from "react-responsive-carousel";
import photoOne from "../assets/myphotoOne.jpeg";
import photoTwo from "../assets/myphotoTwo.jpeg";
import photoThree from "../assets/myPhotoThree.jpeg";
import photoFour from "../assets/myPhotoFour.jpeg";
import photoFive from "../assets/myPhotoFive.jpeg";
import ph1 from "../assets/usOne.jpeg";
import ph2 from "../assets/quadriceps.jpeg";
import ph3 from "../assets/asudePhoto1.jpeg";
import ph4 from "../assets/importantPhoto.jpeg";
import ph5 from "../assets/kids.jpeg";
import ph6 from "../assets/usThree.jpeg";
import ph7 from "../assets/youngKaanOne.jpeg";
import ph8 from "../assets/youngKaanTwo.jpeg";
import ph9 from "../assets/yougThree.jpeg";
import ph10 from "../assets/usTwo.jpeg";
import { useState } from "react";
import { useEffect } from "react";
export const HomeCarousel = () => {
  const [carouselWidth, setCarouselWidth] = useState(window.innerWidth <= 576 ? 300 : 400);

  useEffect(() => {
    const handleResize = () => {
      setCarouselWidth(window.innerWidth <= 576 ? 300 : 400);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Carousel dynamicHeight={true} width={carouselWidth} showThumbs={false} showArrows={false} autoPlay={true}>
      <div>
        <img style={imgStyle} alt="" src={photoOne} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={photoTwo} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={photoThree} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={photoFour} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={photoFive} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph1} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph2} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph3} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph4} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph5} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph6} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph7} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph8} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph9} />
      </div>
      <div>
        <img style={imgStyle} alt="" src={ph10} />
      </div>
    </Carousel>
  )
};

//create a style for images

const imgStyle = {
    
};
