// Home.js
import React from "react";
import { HomeCarousel } from "../components/HomeCarousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Heart } from "lucide-react";
import { Smile } from "lucide-react";
import { useState } from "react";
import toast from 'react-hot-toast';
const Home = () => {

  const [loveCount, setLoveCount] = useState(0);
  const [heartActive, setHeartActive] = useState(false);

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <HomeCarousel />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <Heart onClick={() => {
            if (heartActive) {
              if (loveCount >= 19) {
                toast('Love overdose', {
                  icon: <Smile />
                });
              }
              setHeartActive(false);
              setLoveCount(loveCount + 1);
              toast('Asude bebek', {
                icon:<Heart/>
              });
            }
            else {
              setHeartActive(true);
              setLoveCount(loveCount + 1);
              toast('I love you', {
                icon: <Heart />
                
              });
            }
          }} color={heartActive ? "#FFEFEF" : "#A87676"} size={35} />
        </div>
        <div style={{ paddingBottom: 50 }}>
          <h1 style={{ textAlign: "center", marginTop: 10 }}>Asude & Kaan</h1>
          <h2 style={{ textAlign: "center", marginTop: 20, fontSize: 30 }}>
            You are a great friend, a lovely companion, a beautiful girlfriend
          </h2>
          <h1 style={{ textAlign: "center", marginTop: 20 }}>
            I love you Asude
          </h1>
          <h2 style={{ textAlign: "center" }}>Love Score: {loveCount}</h2>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          {Array(loveCount).fill().map((_, i) => <Heart key={i} color="black" size={15} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
