import "./App.css";
import Home from "./pages/Home.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar2 from "./components/Navbar2.js";
import Footer from "./components/Footer.js";
import Games from "./pages/Games.js";
import Blog from "./pages/Blog.js";
import { Toaster } from 'react-hot-toast';

import "react-responsive-carousel/lib/styles/carousel.min.css";
function App() {
  return (
    <div style={{ backgroundColor: "#E1ACAC" }}>
      <Router>
        <Navbar2 />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 1000,
            style: {
              background: "#A87676",
              color: "white",
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/games" element={<Games />} />
          <Route path="/blog" element={<Blog />} />
          {/* Other routes go here */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
