export default function Blog() {
    
    return (
        <>
        <div style={{paddingBottom:600,paddingTop:10}}>
                

        </div>
        </>
    );


}